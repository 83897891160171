<template>
	<v-row class="h-100 justify-center align-center">
		<v-card width="325" class="py-5 rounded-xl">
            <v-card-title>PONCHES FINCA</v-card-title>
            <v-card-subtitle>PORTAL DE ADMIN</v-card-subtitle>
            <v-card-text>
                <v-form ref="form" @submit.prevent="handle_login">
                    <v-text-field v-model="username" :rules="rules" type="text" label="Username" outlined dense required class="rounded-lg"></v-text-field>
                    <v-text-field v-model="password" :rules="rules" :type="input_type" label="Password" :append-icon="icon" @click:append="show_password = !show_password" outlined dense required class="rounded-lg"></v-text-field>
                    <div class="d-flex justify-end">
                        <v-btn :loading="loading" dark type="submit">Login</v-btn>
                    </div>
                </v-form>
            </v-card-text>
		</v-card>
	</v-row>
</template>
<script>
import { mapActions } from 'vuex'

export default {
    name: 'Login',
    data: () => ({
        loading     : false,

        username	: '',
        password 	: '',
        show_password: false,
        rules       : [ v => !!v || 'Campo es requerido' ],
	}),
	methods: {
		...mapActions(['login']),
        handle_login() {
            if (!this.$refs.form.validate()) return
            this.loading = true
            this.login({ username: this.username, password: this.password }).finally(() => { this.loading = false })
        }
	},
    computed: {
        input_type() {
            return this.show_password
            ? 'text'
            : 'password'
        },
        icon() {
            return this.show_password
            ? 'mdi-eye-off'
            : 'mdi-eye'
        }
    }
}
</script>
